<template>
  <div class="box box-default" v-if="!isLoading">
    <div class="box-body">
      <div class="row">
        <div class="col-md-12">
          <div v-if="chartTransactionsData">
            <Highcharts :options="chartTransactionsData" />
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div v-if="chartVolumeData">
            <Highcharts :options="chartVolumeData" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div v-if="chartRevenueData">
            <Highcharts :options="chartRevenueData" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as moment from "moment-timezone";
import Highcharts from "highcharts";
import { genComponent } from "vue-highcharts";
import loadDrilldown from "highcharts/modules/drilldown.js";
import { START_LOADING, STOP_LOADING } from "../store/keys";

export default {
  name: "Charts",
  components: {
    Highcharts: genComponent("Highcharts", Highcharts),
  },
  data() {
    return {
      chartTransactionsData: null,
      chartVolumeData: null,
      chartRevenueData: null,
      startTime: null,
    };
  },
  computed: {
    ...mapGetters([
      "jwtToken",
      "isLoading",
    ]),
  },
  methods: {
    generateLabels() {
      moment.tz.setDefault("UTC");
      const labels = [];
      const date = moment.utc(this.startTime, "YYYY-MM-DD hh:mm:ss").tz("UTC");
      let time = date.format("hh A");
      labels.push(time);
      for (let i = 1; i < 24; i++) {
        const timeParts = time.toString().split(" ");
        if (timeParts[0] < 12) {
          time = (Number(timeParts[0]) + 1).toString().padStart(2, "0") + " " + (Number(timeParts[0]) === 11 ? (timeParts[1] === "PM" ? "AM" : "PM") : timeParts[1]);
        } else {
          time = "01 " + (Number(timeParts[0]) === 11 ? (timeParts[1] === "PM" ? "AM" : "PM") : timeParts[1]);
        }
        labels.push(time);
      }
      return labels;
    },
    getChartsData() {
      this.$store.commit(START_LOADING);
      const url = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${url}/?Call=Charts`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
      }).then(response => {
        this.$store.commit(STOP_LOADING);
        const data = response.data.data;
        this.startTime = data.date;
        const labels = this.generateLabels();
        this.chartTransactionsData = {
          lang: {
            drillUpText: "Back",
          },
          chart: {
            type: "column",
          },
          title: {
            text: "Created / Confirmed Transactions",
          },
          subtitle: {
            text: "",
          },
          accessibility: {
            announceNewData: {
              enabled: true,
            },
          },
          xAxis: {
            type: "category",
          },
          yAxis: {
            min: 0,
            title: {
              text: "Number of Transactions",
            },
            stackLabels: {
              enabled: true,
              style: {
                fontWeight: "bold",
                color: ( // theme
                  Highcharts.defaultOptions.title.style &&
                  Highcharts.defaultOptions.title.style.color
                ) || "gray",
              },
            },
          },
          tooltip: {
            formatter: function (tooltip) {
              let html = `<span>${this.points[0].key}</span>`;
              let total = 0;
              this.points.forEach(function (point) {
                total += point.y;
                html += "<br/><span style=\"color:" + point.series.color + "\">\u25CF</span> " + point.series.name + ": " + point.y;
              });
              html += "<br/><span style=\"color:black\">\u25CF</span> Total: " + total;
              return html;
            },
            shared: true,
            useHTML: true,
          },
          plotOptions: {
            column: {
              stacking: "normal",
              dataLabels: {
                enabled: true,
              },
            },
          },
          series: [],
          drilldown: {
            series: [],
          },
        };
        this.chartVolumeData = {
          lang: {
            drillUpText: "Back",
          },
          chart: {
            type: "column",
          },
          title: {
            text: "Total / Confirmed Transactions Volume",
          },
          subtitle: {
            text: "",
          },
          accessibility: {
            announceNewData: {
              enabled: true,
            },
          },
          xAxis: {
            type: "category",
          },
          yAxis: {
            labels: {
              // eslint-disable-next-line no-template-curly-in-string
              format: "€{value}",
            },
            min: 0,
            title: {
              text: "Amount in Euro (EUR)",
            },
            stackLabels: {
              enabled: true,
              style: {
                fontWeight: "bold",
                color: ( // theme
                  Highcharts.defaultOptions.title.style &&
                  Highcharts.defaultOptions.title.style.color
                ) || "gray",
              },
              formatter() {
                const points = this.points;
                let max = 0;
                for (const i in points) {
                  var currentValue = Math.abs(points[i][0] - points[i][1]);
                  if (currentValue >= max) {
                    max = currentValue;
                  }
                }
                return "€" + max.toFixed(2);
              },
            },
          },
          tooltip: {
            formatter: function (tooltip) {
              let html = `<span>${this.points[0].key}</span>`;
              let total = 0;
              this.points.forEach(function (point) {
                total += point.y;
                html += "<br/><span style=\"color:" + point.series.color + "\">\u25CF</span> " + point.series.name + ": €" + Highcharts.numberFormat(point.y, 2, ".", ",");
              });
              html += "<br/><span style=\"color:black\">\u25CF</span> Total: €" + Highcharts.numberFormat(total, 2, ".", ",");
              return html;
            },
            shared: true,
            useHTML: true,
          },
          plotOptions: {
            column: {
              stacking: "normal",
              dataLabels: {
                enabled: false,
              },
            },
          },
          series: [],
          drilldown: {
            series: [],
          },
        };
        this.chartRevenueData = {
          chart: {
            type: "column",
          },
          title: {
            text: "Profits",
          },
          subtitle: {
            text: "",
          },
          accessibility: {
            announceNewData: {
              enabled: true,
            },
          },
          xAxis: {
            type: "category",
          },
          yAxis: {
            labels: {
              // eslint-disable-next-line no-template-curly-in-string
              format: "€{value}",
            },
            min: 0,
            title: {
              text: "Amount in Euro (EUR)",
            },
            stackLabels: {
              enabled: true,
              style: {
                fontWeight: "bold",
                color: ( // theme
                  Highcharts.defaultOptions.title.style &&
                  Highcharts.defaultOptions.title.style.color
                ) || "gray",
              },
              formatter() {
                const points = this.points;
                let total = 0;
                for (const i in points) {
                  total = Math.max(points[i][0], points[i][1]);
                }
                return "€" + total.toFixed(2);
              },
            },
          },
          tooltip: {
            formatter: function (tooltip) {
              let html = `<span>${this.points[0].key}</span>`;
              let total = 0;
              this.points.forEach(function (point) {
                total += point.y;
                html += "<br/><span style=\"color:" + point.series.color + "\">\u25CF</span> " + point.series.name + ": €" + Highcharts.numberFormat(point.y, 2, ".", ",");
              });
              html += "<br/><span style=\"color:black\">\u25CF</span> Total: €" + Highcharts.numberFormat(total, 2, ".", ",");
              return html;
            },
            shared: true,
            useHTML: true,
          },
          plotOptions: {
            column: {
              stacking: "normal",
              dataLabels: {
                enabled: false,
              },
            },
          },
          series: [],
        };
        // Transactions
        const totalTransactions = [];
        const totalTransactionsDrillDown = [];
        const totalConfirmedTransactions = [];
        const totalConfirmedTransactionsDrillDown = [];
        labels.forEach(lbl => {
          totalTransactions.push({
            name: lbl,
            y: 0,
            drilldown: "total-" + lbl,
          });
          totalTransactionsDrillDown.push({
            id: "total-" + lbl,
            name: "Created Transactions",
            data: [],
          });
          totalConfirmedTransactions.push({
            name: lbl,
            y: 0,
            drilldown: "confirmed-" + lbl,
          });
          totalConfirmedTransactionsDrillDown.push({
            id: "confirmed-" + lbl,
            name: "Confirmed Transactions",
            data: [],
          });
        });
        if (data.TotalTransactions) {
          data.TotalTransactions.forEach(t => {
            const time = moment.utc(t.HourTime, "YYYY-MM-DD hh:mm:ss").tz("UTC").format("hh A");
            const index = labels.indexOf(time);
            if (index !== -1) {
              totalTransactions[index].y += t.Total;
              totalTransactionsDrillDown[index].data.push([t.PaymentProvider, t.Total]);
            }
          });
          this.chartTransactionsData.series.push({
            name: "Created Transactions",
            data: totalTransactions,
          });
        }
        if (data.TotalConfirmedTransactions.length > 0) {
          data.TotalConfirmedTransactions.forEach(t => {
            const time = moment.utc(t.HourTime, "YYYY-MM-DD hh:mm:ss").tz("UTC").format("hh A");
            const index = labels.indexOf(time);
            if (index !== -1) {
              totalConfirmedTransactions[index].y += t.Total;
              totalConfirmedTransactionsDrillDown[index].data.push([t.PaymentProvider, t.Total]);
            }
          });
        }
        this.chartTransactionsData.drilldown.series = totalTransactionsDrillDown.concat(totalConfirmedTransactionsDrillDown);
        this.chartTransactionsData.series.push({
          name: "Confirmed Transactions",
          data: totalConfirmedTransactions,
        });
        // Volume
        const totalVolume = [];
        const totalVolumeDrillDown = [];
        const totalPaidVolume = [];
        const totalPaidVolumeDrillDown = [];
        labels.forEach(lbl => {
          totalVolume.push({
            name: lbl,
            y: 0,
            drilldown: "total-" + lbl,
          });
          totalVolumeDrillDown.push({
            id: "total-" + lbl,
            name: "Created Transactions Volume",
            data: [],
          });
          totalPaidVolume.push({
            name: lbl,
            y: 0,
            drilldown: "paid-" + lbl,
          });
          totalPaidVolumeDrillDown.push({
            id: "paid-" + lbl,
            name: "Confirmed Transactions Volume",
            data: [],
          });
        });
        if (data.TotalVolume) {
          data.TotalVolume.forEach(t => {
            const time = moment.utc(t.HourTime, "YYYY-MM-DD hh:mm:ss").tz("UTC").format("hh A");
            const index = labels.indexOf(time);
            if (index !== -1) {
              totalVolume[index].y += t.Volume;
              totalVolumeDrillDown[index].data.push([t.PaymentProvider, t.Volume]);
            }
          });
          this.chartVolumeData.series.push({
            name: "Created Transactions Volume",
            data: totalVolume,
          });
        }
        if (data.TotalConfirmedVolume.length > 0) {
          data.TotalConfirmedVolume.forEach(t => {
            const time = moment.utc(t.HourTime, "YYYY-MM-DD hh:mm:ss").tz("UTC").format("hh A");
            const index = labels.indexOf(time);
            if (index !== -1) {
              totalPaidVolume[index].y += t.Volume;
              totalPaidVolumeDrillDown[index].data.push([t.PaymentProvider, t.Volume]);
            }
          });
        }
        this.chartVolumeData.drilldown.series = totalVolumeDrillDown.concat(totalPaidVolumeDrillDown);
        this.chartVolumeData.series.push({
          name: "Confirmed Transactions Volume",
          data: totalPaidVolume,
        });
        // Profits Transactions
        const transactionsRevenue = data.TransactionsRevenueInEUR;
        const transactionsRevenueData = transactionsRevenue.map(r => {
          return [
            r.date, r.revenue,
          ];
        });
        this.chartRevenueData.series.push({
          name: "Transactions Profits",
          data: transactionsRevenueData,
        });
        // Profits Payouts
        const payoutsRevenue = data.PayoutsRevenueInEUR;
        const payoutsRevenueData = payoutsRevenue.map(r => {
          return [
            r.date, r.revenue,
          ];
        });
        this.chartRevenueData.series.push({
          name: "Payouts Profits",
          data: payoutsRevenueData,
        });
      }).catch(error => {
        this.$store.commit(STOP_LOADING);
        console.log(error.response.data.message);
      });
    },
  },
  mounted() {
    loadDrilldown(Highcharts);
    this.getChartsData();
  },
};
</script>

<style scoped lang="scss"></style>
